import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from './app/app.component';
import { HttpErrorInterceptor } from './app/interceptors/http-error.interceptor';
import { NotFoundComponent } from './app/not-found/not-found.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { SignInComponent } from './app/auth/sign-in/sign-in.component';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule,
      SharedModule,
      ButtonModule,
      RippleModule,
      MessagesModule,
      ToastModule
    ),
    MessageService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient]
      }
    }).providers!,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideCacheableAnimationLoader(),
    provideRouter([
      { path: "", pathMatch: "full", redirectTo: "dashboard" },
      { path: "invite/:slug", component: SignInComponent },
      {
        path: "auth",
        loadChildren: () => import("./app/auth/auth.routes").then(m => m.AUTH_ROUTES)
      },
      {
        path: "dashboard",
        loadChildren: () => import("./app/dashboard/dashboard.routes").then(m => m.DASHBOARD_ROUTES),
      },
      { path: "not-found", component: NotFoundComponent },
      { path: "**", redirectTo: "not-found" }])
  ]
}).catch(err => console.error(err));
